import {CSS_CLASSES} from "../misc/cssClasses";
import {closeKebab} from "../kebab/setupKebab.js";

/**
 * Open the Main Menu
 */
export default function openMainMenu() {
	const mainMenu = document.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU)[0];
	const hiveburger = document.getElementsByClassName(CSS_CLASSES.HIVEBURGER)[0];

	hiveburger.classList.add(CSS_CLASSES.HIVEBURGER__OPEN);
	hiveburger.setAttribute('aria-label', 'Close Utah.gov menu');
	mainMenu.classList.remove(CSS_CLASSES.SLIDE_OUT);
	mainMenu.classList.add(CSS_CLASSES.HIVEBURGER_MAIN_MENU__OPEN, CSS_CLASSES.SLIDE_IN);

	//focus on the first menu item after the menu is opened.
	let t = setTimeout(() => {
		let menuItem = mainMenu.querySelector('.' + CSS_CLASSES.HIVEBURGER_MAIN_MENU__CUSTOM_MENU + ' .' + CSS_CLASSES.UT_MENU_ITEM);
		if (!menuItem) {
			menuItem = mainMenu.querySelector('.' + CSS_CLASSES.UT_MENU_ITEM);
		}
		if (menuItem) {
			menuItem.focus();
		}
		clearTimeout(t);
	}, 100);

	closeKebab();
}