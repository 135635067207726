import { CSS_CLASSES } from "../misc/cssClasses";

/**
 * Set the title of an action item. You must pass a CSS Class selector or an actual HTMLElement
 * @param {string|HTMLElement} actionItem - A CSS Class or HTMLElement that points to the action item
 * @param {string} title - The title for the action item.
 */
export default function ({ actionItem, title }) {
	const ai = (actionItem instanceof HTMLElement) ? actionItem : document.getElementsByClassName(actionItem)[0];
	const aiTitle = ai.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEM__TITLE)[0];
	const aiTitleText = aiTitle.childNodes[0];
	aiTitleText.nodeValue = title;
}