import SearchPopup from "./SearchPopup";
import {CSS_CLASSES} from "../misc/cssClasses";

/**
 * @callback searchCallback
 * @returns {string} - The search query string the user has input
 */

/**
 * Open the search form overlay
 * Overlay is created, and added to the DOM
 * @param {String} placeholder - The placeholder text in the search input box
 * @param {searchCallback} searchCallback - Callback when the user submits the search form. Function provides the query of the search
 */
export default function openSearchForm({ placeholder, searchCallback }) {
	const searchPopup = SearchPopup({ placeholder, searchCallback });
	document.body.appendChildAll(searchPopup);
	searchPopup.getElementsByClassName(CSS_CLASSES.UT_SEARCH_INPUT)[0].focus();
}