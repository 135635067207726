import {CSS_CLASSES} from "../misc/cssClasses";
import HEADER_STYLE from "../misc/headerStyle";
import HEADER_SIZE from "../misc/headerSize";
import renderDOM from "../misc/renderDOM";
import verticalLineHtml from "../utahGovLogo/VerticalLine.html";
import renderLogoTitleWrapper from "../headerWrapper/LogoTitleWrapper.js";
import utGlobals, {globalsDefaults} from "./globals";
import ActionItem from "../headerActionItems/ActionItem";
import utahIdIconHtml from "../misc/icons/UtahIdIcon.html";
import stringConstants from "../misc/stringConstants";
import popupPanel from "../popupPanel/popupPanel";
import utahIdMenu from "../utahID/utahIdMenu";
import {checkUserInfo, removeOnUserClickedSignIn, showSignIn} from "../utahID/utahIdAjax";
import utahIdMenuCustom from "../utahID/utahIdMenuCustom";
import appGridIconHtml from "../misc/icons/AppGridIcon.html";
import questionIconHtml from "../misc/icons/QuestionIcon.html";
import gearIconHtml from "../misc/icons/GearIcon.html";
import searchIconHtml from "../misc/icons/SearchIcon.html";
import openSearchForm from "../searchPopup/openSearchForm";
import closeSearchForm from "../searchPopup/closeSearchForm";
import headerResizeConfig from "../headerResize/headerResizeConfig";
import {setupKebab} from "../kebab/setupKebab.js";

/**
 * Set the header look and feel and functionality.
 *
 * This function should be called after 'ugovHeaderLoaded' event has fired on
 * document or window.
 *
 * If this function is called before the 'ugovHeaderLoaded' has fired, then
 * this function will add the appropriate listener to call itself.
 *
 * @param {string} headerStyle - One of the colors defined in HEADER_STYLE enum
 * @param {string} headerSize - One of the sizes defined in the HEADER_SIZE enum
 * @param {string} headerTitle - The title for the header, defaults to the page title
 * @param {string} headerShortTitle - The short title for the header for mobile screens (should be no longer than ~20 characters long)
 * @param {*} headerLogo - SVG, Function, Dom Node, or Image URL
 * @param {*} headerLogoAltText - Text to put as alt text on header logo wrapper
 * @param {string} headerLogoType - The type of headerLogo, see See ICON_TYPES enum
 * @param {string} headerTitleURL - An optional URL to be used for the Title / Logo
 * @param {function} headerTitleOnClick - An optional onClick callback function when you click the Title / Logo
 * @param {object|boolean} utahId - Configuration for the UtahId, Sign In, Sign Out, etc. action item
 * 		@param {string} utahId.environment - Environment from ENVIRONMENTS enum
 * 		@param {string} utahId.customURL - A custom url for the UtahID web service api
 * 		@param {string[]} utahId.customParams - An array of custom parameters to use for the UtahID api
 * 		@param {function} utahId.authCallback - A function that is triggered when the users authentication changes
 * @param {object} utahIdCustom - Configurations for a custom implementation of UtahID
 * 		@param {function} utahIdCustom.onUserClickedSignIn - A function that is triggered when the user clicks to sign in
 * 		@param {function} utahIdCustom.onUserClickedSignOut - A function that is triggered when the user clicks to sign out
 * 		@param {string} utahIdCustom.profileURL - The URL of the users UtahID profile, this must be supplied for a profile menu item to be generated
 * @param {object} appGridIcon - Configuration for the appGridIcon action item
 * 		@param {string} appGridIcon.title - Title of the app grid icon action item
 * 		@param {object} appGridIcon.menu - A menu for the app grid icon action item
 * 		@param {string} appGridIcon.menuStyle - A menu style for the app grid icon action item - See POPUP_MENU_STYLE enum
 * 		@param {function} appGridIcon.onClick - A function to call when the user clicks the app grid icon action item
 * 		@param {function} appGridIcon.onHover - A function to call when the user hovers the app grid icon action item
 * 		@param {ChildNode} appGridIcon.popupContent - Arbitrary Dom content to display in a popup panel when the user clicks this action item
 * 		@param {boolean} appGridIcon.showTitle - Override showing the icon title
 * @param {object} questionIcon - Configuration for the questionIcon action item
 * 		@param {string} questionIcon.title - Title of this action item
 * 		@param {object} questionIcon.menu - A menu for this action item
 * 		@param {string} questionIcon.menuStyle - A menu style for this action item - See POPUP_MENU_STYLE enum
 * 		@param {function} questionIcon.onClick - A function to call when the user clicks this action item
 * 		@param {function} questionIcon.onHover - A function to call when the user hovers this action item
 * 		@param {ChildNode} questionIcon.popupContent - Arbitrary Dom content to display in a popup panel when the user clicks this action item
 * 		@param {boolean} questionIcon.showTitle - Override showing the icon title
 * @param {object} gearIcon - Configuration for the gearIcon action item
 * 		@param {string} gearIcon.title - Title of this action item
 * 		@param {object} gearIcon.menu - A menu for this action item
 * 		@param {string} gearIcon.menuStyle - A menu style for this action item - See POPUP_MENU_STYLE enum
 * 		@param {function} gearIcon.onClick - A function to call when the user clicks this action item
 * 		@param {function} gearIcon.onHover - A function to call when the user hovers this action item
 * 		@param {ChildNode} gearIcon.popupContent - Arbitrary Dom content to display in a popup panel when the user clicks this action item
 * 		@param {boolean} gearIcon.showTitle - Override showing the icon title
 * @param {object} search - Configuration for the search action item
 * 		@param {string} search.title - The title of the action item
 * 		@param {function} search.onClick - callback for when search icon is clicked (instead of showing default search popup)
 * 		@param {string} search.placeholder - The placeholder text in the search field
 * 		@param {function} search.searchCallback - The callback function that will trigger and give the query the user has made; searchQuery => {...your code...}
 * 		@param {boolean} search.showTitle - Override showing the icon title
 * @param {[string]} modifierClasses - Array of css modifier classes. e.g.: ["sticky", "no-shadow"]
 * @param {function} onHeaderResize - The callback function that will trigger when the header's size changes do to a resize
 */
export default function setConfig({ headerStyle, headerSize, headerTitle, headerShortTitle, headerLogo, headerLogoAltText, headerLogoType, headerTitleURL, headerTitleOnClick, utahId, utahIdCustom, appGridIcon, gearIcon, questionIcon, search, modifierClasses, onHeaderResize }) {
	const previousConfig = utGlobals.currentConfig;
	utGlobals.currentConfig = arguments[0];

	const header = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER)[0];
	const actionItemsWrapper = document.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEMS_WRAPPER)[0];
	const mainMenu = document.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU)[0];
	const logoTitleWrapper = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_LOGO_TITLE_WRAPPER)[0];

	let renderedActionItems = false;

	if (!header) {
		// The header DOM element is not present yet.
		// Re-run this function after the ugovHeaderLoaded event has fired.
		window.addEventListener('ugovHeaderLoaded', function() {
			setConfig(utGlobals.currentConfig);
		});
		return;
	}

	/**
	 * Set header style (color) and size
	 */

	//remove header style color css classes
	Object.keys(HEADER_STYLE).forEach(s => {
		header.classList.remove(HEADER_STYLE[s]);
		mainMenu.classList.remove(HEADER_STYLE[s]);
	});

	//remove header size css classes
	Object.keys(HEADER_SIZE).forEach(s => {
		header.classList.remove(HEADER_SIZE[s]);
		mainMenu.classList.remove(HEADER_SIZE[s]);
	});

	//set header style
	if (headerStyle && headerStyle !== HEADER_STYLE.DEFAULT) {
		header.classList.add(headerStyle);
		mainMenu.classList.add(headerStyle);
	}

	//set header size
	if (headerSize) {
		header.classList.add(headerSize);
		mainMenu.classList.add(headerSize);
	} else {
		//default to small
		header.classList.add(CSS_CLASSES.UT_SIZE_SMALL);
		mainMenu.classList.add(CSS_CLASSES.UT_SIZE_SMALL);
	}

	/**
	 * Add modifier classes such as "sticky" and "no-shadow"
	 */

	const previousClasses = (previousConfig && previousConfig.modifierClasses) || [];
	const newClasses = modifierClasses || [];

	// remove previous not in new
	previousClasses.filter(c => !newClasses.indexOf(c) >= 0)
		.forEach(c => header.classList.remove(`${CSS_CLASSES.UTAH_GOV_HEADER}--${c}`));

	// add new not in previous
	newClasses.filter(c => !previousClasses.indexOf(c) >= 0)
		.forEach(c => header.classList.add(`${CSS_CLASSES.UTAH_GOV_HEADER}--${c}`));

	//Sticky header
	if (modifierClasses && modifierClasses.indexOf('sticky') >= 0) {
		let placeholder = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_STICKY_PLACEHOLDER)[0];

		if (!placeholder) {
			placeholder = document.createElement('div');
			placeholder.classList.add(CSS_CLASSES.UTAH_GOV_HEADER_STICKY_PLACEHOLDER);
			document.body.insertBefore(placeholder, header);
		}

		Object.keys(HEADER_SIZE).forEach(s => {
			placeholder.classList.remove(HEADER_SIZE[s]);
		});

		if (headerSize) {
			placeholder.classList.add(headerSize);
		} else {
			placeholder.classList.add(CSS_CLASSES.UT_SIZE_SMALL);
		}

		mainMenu.classList.add(CSS_CLASSES.HIVEBURGER_MAIN_MENU_STICKY);
	} else {
		let placeholder = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_STICKY_PLACEHOLDER);
		placeholder && placeholder[0] && placeholder[0].remove();
		mainMenu.classList.remove(CSS_CLASSES.HIVEBURGER_MAIN_MENU_STICKY);
	}

	/**
	 * Add the vertical line between the utah.gov logo and the title / custom logo
	 */

	const vertLine = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_VERT_LINE)[0];
	if (headerLogo || headerTitle) {
		if (!vertLine) {
			header.insertBefore(renderDOM(verticalLineHtml), logoTitleWrapper);
		}
	} else if (vertLine) {
		vertLine.remove();
	}

	/**
	 * Render the Title and Logo
	 */
	renderLogoTitleWrapper({
		title: headerTitle,
		shortTitle: headerShortTitle,
		logo: headerLogo,
		logoAltText: headerLogoAltText,
		logoType: headerLogoType,
		url: headerTitleURL,
		onClick: headerTitleOnClick,
	});

	/**
	 * Add all action items that are configured to be used
	 */

	//remove all action items
	const actionItemElements = header.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEM);
	const actionItemsArray = Array.prototype.slice.call(actionItemElements, 0);
	actionItemsArray.forEach(i => i.parentNode.removeChild(i));

	//UtahId action item and configuration
	if (utahId) {
		renderedActionItems = true;

		if (typeof utahId === 'object') {
			if (utahId.environment) {
				utGlobals.environment = utahId.environment;
			} else {
				utGlobals.environment = globalsDefaults.environment;
			}

			if (utahId.customURL) {
				utGlobals.utahIdURL = utahId.customURL;
			} else {
				utGlobals.utahIdURL = globalsDefaults.utahIdURL;
			}

			if (utahId.customParams) {
				utGlobals.utahIdParams = utahId.customParams;
			} else {
				utGlobals.utahIdParams = globalsDefaults.customParams;
			}
		}

		actionItemsWrapper.appendChildAll(ActionItem({
			icon: renderDOM(utahIdIconHtml),
			title: stringConstants.SIGN_IN,
			className: CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID,
			showTitle: true,
			onHover: (e => {
				const ai = document.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID)[0];
				if (ai.classList.contains(CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID_AUTH)) {
					e.preventDefault();
					e.stopPropagation();
					popupPanel({
						parent: ai,
						content: utahIdMenu(),
						headerStyle: headerStyle,
						stayOpen: true,
					})
				}
			}),
		}));

		const authCallback = utahId.authCallback ? utahId.authCallback : undefined;

		if (!utGlobals.windowFocusListener) {
			window.addEventListener('focus', e => {
				checkUserInfo({authCallback});
			});
			utGlobals.windowFocusListener = true;
		}
		checkUserInfo({authCallback});
	}

	// custom UtahID where you must provide the authentication status
	// You must call setSignedInStatus public function to change the Action Item / Menu
	if (!utahId && utahIdCustom) {
		renderedActionItems = true;

		actionItemsWrapper.appendChildAll(ActionItem({
			icon: renderDOM(utahIdIconHtml),
			title: stringConstants.SIGN_IN,
			className: CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID,
			showTitle: true,
			onHover: (e => {
				const ai = document.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID)[0];
				if (ai.classList.contains(CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID_AUTH)) {
					e.preventDefault();
					e.stopPropagation();
					popupPanel({
						parent: ai,
						content: utahIdMenuCustom({
							profileURL: utahIdCustom.profileURL,
							signOutCallback: utahIdCustom.onUserClickedSignOut
						}),
						headerStyle: headerStyle,
						stayOpen: true,
					})
				}
			}),
		}));
		showSignIn(utahIdCustom.onUserClickedSignIn);
	}
	if (utahId || !utahIdCustom || !utahIdCustom.onUserClickedSignIn) {
		removeOnUserClickedSignIn();
	}

	//App Grid Icon action item
	if (appGridIcon) {
		renderedActionItems = true;
		actionItemsWrapper.appendChildAll(ActionItem({
			icon: renderDOM(appGridIconHtml),
			title: appGridIcon.title ? appGridIcon.title : 'Apps',
			menu: appGridIcon.menu,
			menuStyle: appGridIcon.menuStyle,
			headerStyle: headerStyle,
			onClick: appGridIcon.onClick,
			onHover: appGridIcon.onHover,
			popupContent: appGridIcon.popupContent,
			showTitle: appGridIcon.showTitle,
		}));
	}

	//Question Mark action item
	if (questionIcon) {
		renderedActionItems = true;
		actionItemsWrapper.appendChildAll(ActionItem({
			icon: renderDOM(questionIconHtml),
			title: questionIcon.title ? questionIcon.title : 'Help',
			menu: questionIcon.menu,
			menuStyle: questionIcon.menuStyle,
			headerStyle: headerStyle,
			onClick: questionIcon.onClick,
			onHover: questionIcon.onHover,
			popupContent: questionIcon.popupContent,
			showTitle: questionIcon.showTitle,
		}));
	}

	//Gear action item
	if (gearIcon) {
		renderedActionItems = true;
		actionItemsWrapper.appendChildAll(ActionItem({
			icon: renderDOM(gearIconHtml),
			title: gearIcon.title ? gearIcon.title : 'Settings',
			menu: gearIcon.menu,
			menuStyle: gearIcon.menuStyle,
			headerStyle: headerStyle,
			onClick: gearIcon.onClick,
			onHover: gearIcon.onHover,
			popupContent: gearIcon.popupContent,
			showTitle: gearIcon.showTitle,
		}));
	}

	// Add custom ActionItem elements
	for (const actionItemName in utGlobals.actionItems) {
		const itemConfig = utGlobals.actionItems[actionItemName];

		renderedActionItems = true;

		actionItemsWrapper.appendChildAll(ActionItem({
			className: itemConfig.className,
			iconType: itemConfig.iconType,
			icon: itemConfig.icon,
			title: itemConfig.title,
			menu: itemConfig.menu,
			menuStyle: itemConfig.menuStyle,
			headerStyle: headerStyle,
			onClick: itemConfig.onClick,
			onHover: itemConfig.onHover,
			popupContent: itemConfig.popupContent,
			showTitle: itemConfig.showTitle,
		}));
	}

	//Search action item
	if (search) {
		renderedActionItems = true;
		actionItemsWrapper.appendChildAll(ActionItem({
			icon: renderDOM(searchIconHtml),
			showTitle: search.showTitle,
			title: search.title ? search.title : "Search",
			onClick: e => {
				if (search.onClick) {
					search.onClick(e);
				} else {
					e.preventDefault();
					e.stopPropagation();
					openSearchForm({
						placeholder: search.placeholder ? search.placeholder : "Search",
						searchCallback: query => {
							if (search && search.searchCallback) {
								search.searchCallback(query);
							} else {
								console.warn(`Search config given, but no searchCallback function provided: ${query}`);
							}
							closeSearchForm();
						}
					});
				}
			}
		}));
	}

	/**
	 * Add kebab menu icon (mobile three dots)
	 */

	setupKebab(renderedActionItems);

	/**
	 * Register onHeaderResize callback
	 */

	headerResizeConfig({onHeaderResize}, previousConfig);
}
