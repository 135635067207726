import {CSS_CLASSES} from "../misc/cssClasses";

/**
 * Close the Main Menu
 */
export default function closeMainMenu() {
	const mainMenu = document.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU)[0];
	const hiveburger = document.getElementsByClassName(CSS_CLASSES.HIVEBURGER)[0];

	hiveburger.classList.remove(CSS_CLASSES.HIVEBURGER__OPEN);
	hiveburger.setAttribute('aria-label', 'Open Utah.gov menu');
	mainMenu.classList.remove(CSS_CLASSES.SLIDE_IN);
	mainMenu.classList.add(CSS_CLASSES.SLIDE_OUT);
	let t = setTimeout(() => {
		mainMenu.classList.remove(CSS_CLASSES.HIVEBURGER_MAIN_MENU__OPEN, CSS_CLASSES.SLIDE_OUT);
		clearTimeout(t);
	}, 400)
}