import {CSS_CLASSES} from "../misc/cssClasses";
import closeMainMenu from "./closeMainMenu";
import openMainMenu from "./openMainMenu";

/**
 * Toggle the MainMenu open or closed
 */
export default function toggleMainMenu() {
	const mainMenu = document.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU)[0];

	if (mainMenu.classList.contains(CSS_CLASSES.HIVEBURGER_MAIN_MENU__OPEN)) {
		//close it
		closeMainMenu();
	} else {
		//open it
		openMainMenu();
	}
}