import globals from "../app/globals";
import utGlobals from "../app/globals";
import MenuItem from "../menu/MenuItem";
import renderDOM from "../misc/renderDOM";
import URLS, {generateURL} from "../misc/urls";
import popupMenuHtml from "../popupPanel/popupMenu.html";

export default function () {
	const commonSettings = {
		env: globals.environment,
		params: [].concat([URLS.UTAH_ID_GOTO_PARAM(window.location.href)], globals.utahIdParams),
	};

	let utahIdMenu =  [
		{
			text: 'UtahID Profile',
			url: generateURL({
				domain: globals.utahIdURL,
				path: '',
				env: globals.environment,
				params: [].concat(globals.utahIdParams),
			}),
			openInNewTab: true,
		},
		{
			text: 'Sign Out',
			url: (utGlobals.utahIdURLs && utGlobals.utahIdURLs.signout) ?
				generateURL({
					domain: utGlobals.utahIdURLs.signout,
					path: '',
					...commonSettings,
				})
				: generateURL({
					domain: globals.utahIdURL,
					path: URLS.UTAH_ID_LOGOUT,
					...commonSettings,
				}),
		},
	];

	let ul = renderDOM(popupMenuHtml);

	utahIdMenu.forEach(i => {
		ul.appendChild(MenuItem(i));
	});

	return ul;
}
