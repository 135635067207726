import {version} from "../../../package";
import "../../css/index.scss";
import HeaderWrapper from "../headerWrapper/HeaderWrapper";
import MainMenu from "../menu/MainMenu";
import {CSS_CLASSES} from "../misc/cssClasses";
import escapeRegex from "../misc/escapeRegex";
import renderDOM from "../misc/renderDOM";
import '../misc/Prototypes';
import {
	addActionItem,
	closeMainMenu,
	closeSearchForm,
	ENVIRONMENT,
	getConfig,
	getMainMenu,
	getUserInfo,
	HEADER_SIZE,
	HEADER_STYLE,
	ICON_TYPES,
	openMainMenu,
	openSearchForm,
	POPUP_MENU_STYLE,
	removeActionItem,
	setConfig,
	setMainMenu,
	showSignIn,
	showUserAsSignedIn,
	toggleMainMenu,
	updateConfig,
} from './Public';

let isLoaded = false;

const fileVersion = version.replace(/\./g,'');
const cssFileName = `utahHeader.css`;
const jsFileName = `utahHeader.bundle.js`;

//use same path as the javascript file
let cssFilePath = Array.prototype.filter.call(document.getElementsByTagName('script'), i => {
	let regex = new RegExp(escapeRegex(jsFileName));
	return i.getAttribute('src') && i.getAttribute('src').search(regex) >= 0;
})[0];

//initialize css to not display the header or menu so that it doesn't flicker before the CSS file loads
const loadingCSS = `.${CSS_CLASSES.UTAH_GOV_HEADER}, .${CSS_CLASSES.HIVEBURGER_MAIN_MENU}{display:none;}`;
const loadingHTML = document.createElement('style');
loadingHTML.innerHTML = loadingCSS;
document.head.appendChild(loadingHTML);

// jest tests don't have a <script> element, nor do they need css
if (cssFilePath) {
	cssFilePath = cssFilePath.src.split(jsFileName)[0];
	//write css link href to head
	const cssLink = document.createElement('link');
	cssLink.setAttribute('rel', 'stylesheet');
	cssLink.setAttribute('href', cssFilePath + cssFileName);
	document.head.appendChild(cssLink);
} else if (!window.process) {
	console.error('Unable to locate the cssFilePath. The HTML may be missing a "script" tag. Please contact the Utah Header developers.')
}

// Manipulate the DOM only after it has loaded.
if (document.readyState === 'loading') {
	window.addEventListener('DOMContentLoaded', function () {
		loadHeader();
	});
} else {
	loadHeader();
}

/**
 * Will load the header in its default state and size
 */
function loadHeader() {
	//Load the Main Menu
	if (document.body.firstChild) {
		document.body.insertBefore(MainMenu(), document.body.firstChild);
	} else {
		document.body.appendChildAll(MainMenu());
	}

	//Load the Header Wrapper
	const header = HeaderWrapper();
	document.body.insertBefore(header, document.body.firstChild);

	isLoaded = true;
	// Trigger a custom event ('ugovHeaderLoaded') that developers can listen for
	// in their applications.
	// The event needs to wait for the UMD library to load the global window.utahHeader
	// module. Use setTimeout to wait for this script to finish running before firing
	// the `ugovHeaderLoaded` event.
	setTimeout(function () {
		const event = document.createEvent('CustomEvent');
		event.initEvent('ugovHeaderLoaded', true, true);
		document.dispatchEvent(event);
	}, 0);
}

export {
	ENVIRONMENT,
	HEADER_SIZE,
	HEADER_STYLE,
	ICON_TYPES,
	POPUP_MENU_STYLE,
	addActionItem,
	closeMainMenu,
	closeSearchForm,
	getConfig,
	getMainMenu,
	getUserInfo,
	isLoaded,
	loadHeader,
	openMainMenu,
	openSearchForm,
	removeActionItem,
	renderDOM,
	setConfig,
	setMainMenu,
	showSignIn,
	showUserAsSignedIn,
	toggleMainMenu,
	updateConfig,
};
