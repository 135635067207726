import {CSS_CLASSES} from "../misc/cssClasses";
import isInternetExplorer from "../misc/isInternetExplorer";
import renderIcon from "../misc/renderIcon";
import headerLogoHTML from "./HeaderLogo.html";
import renderDOM from "../misc/renderDOM";

/**
 * Add custom header logo to the header
 * @param {string} headerTitle - header title if exists for the aria-label
 * @param {*} logo - Function, svg, dom node, image url
 * @param {string} logoType - The type of icon (image) using the ICON_TYPES enum
 * @param {string} url - The URL for the anchor tag around the logo. If no URL or onClick is provided an anchor tag will not be used.
 * @param {function} onClick - a callback function when the user clicks the logo
 */
export default function ({ headerTitle, logo, logoAltText, logoType, url, onClick } = {}) {
	const logoTitleWrapper = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_LOGO_TITLE_INNER_WRAPPER)[0];

	if (logo) {
		let headerLogoDOM = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_LOGO_WRAPPER)[0];
		if (headerLogoDOM && headerLogoDOM.parentNode) {
			headerLogoDOM.parentNode.removeChild(headerLogoDOM);
		}

		headerLogoDOM = renderDOM(headerLogoHTML);

		if (headerTitle) {
			headerLogoDOM.setAttribute('aria-hidden',true);
		} else {
			headerLogoDOM.setAttribute('aria-label', logoAltText || headerTitle || 'logo image');
		}

		renderIcon({
			icon: logo,
			iconType: logoType,
			iconWrapper: headerLogoDOM,
			cssClasses: [CSS_CLASSES.UTAH_GOV_HEADER_LOGO_WRAPPER],
		});

		logoTitleWrapper.appendChild(headerLogoDOM);

		//add width to svg for internet explorer
		if (isInternetExplorer() && headerLogoDOM.children.length > 0 && headerLogoDOM.children[0].tagName.toLowerCase() === 'svg') {
			//add a width to the svg so it will display correctly.
			let svg = headerLogoDOM.children[0];
			let viewBox = svg.getAttribute('viewBox').split(' ');
			let widthToHeightRatio = Number(viewBox[2]) / Number(viewBox[3]);
			let svgHeight = svg.clientHeight;
			svg.style.width = `${svgHeight * widthToHeightRatio}px`;
		}
	}
}
