import {closeSearchForm, openSearchForm} from "../app/Public";
import mainMenuHtml from "./MainMenu.html";
import UtahGovLogo from "../utahGovLogo/UtahGovLogo"
import hiveBackground from "./HiveBackground.html";
import MenuItem from "./MenuItem";
import renderDOM from "../misc/renderDOM";
import {CSS_CLASSES} from "../misc/cssClasses";

/**
 * Utah.gov menu object
 */
const utahMenu = [
	{
		text: 'Visit Utah.gov',
		url: 'https://www.utah.gov',
		openInNewTab: true,
	},
	{
		text: 'Services',
		url: 'https://www.utah.gov/services/',
		openInNewTab: true,
	},
	{
		text: 'Agencies',
		url: 'https://www.utah.gov/government/agencylist.html',
		openInNewTab: true,
	},
	{
		text: 'Search Utah.gov',
		url: '#',
		openInNewTab: true,
		onClick: () => {
			openSearchForm({
				placeholder: 'Search Utah.gov',
				searchCallback: (query) => {
					const queryTerms = query.replace(/ /g, '+');
					if ((queryTerms.length > 0)) {
						window.open(`https://www.utah.gov/search-results.html?q=${queryTerms}`, '_blank');
						closeSearchForm();
					}
				}
			});
		}
	}
];

/**
 * Renders the MainMenu wrapper with the Utah.gov menu
 * Note: The custom menu is added in different place
 * @returns {ChildNode}
 */
export default function () {
	let mainMenu = renderDOM(mainMenuHtml);
	const utahGovMenuContainer = mainMenu.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU__CONTAINER)[0];
	const utahGovMenuUL = utahGovMenuContainer.getElementsByTagName('ul')[0];

	mainMenu.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU__UT_LOGO)[0].appendChildAll(UtahGovLogo());
	mainMenu.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU__UT_MENU)[0].appendChild(renderDOM(hiveBackground));

	utahMenu.forEach(i => {
		utahGovMenuUL.appendChild(MenuItem(i));
	});

	return mainMenu;
}