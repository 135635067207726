import { closeSearchForm } from "../app/Public";
import searchPopupHtml from "./SearchPopup.html";
import searchIconHtml from "../misc/icons/SearchIcon.html";
import xIconHtml from "../misc/icons/X-Icon.html";
import { CSS_CLASSES } from "../misc/cssClasses";;
import renderDOM from "../misc/renderDOM";

/**
 * @callback searchCallback
 * @returns {string} - The search query string the user has input
 */

/**
 * Creates a search form that can be inserted into the DOM
 * @param {String} placeholder - The placeholder text in the search input box
 * @param {searchCallback} searchCallback - Callback when the user submits the search form. Function provides the query of the search
 * @returns {ChildNode}
 */
export default function ({ placeholder, searchCallback }) {
	const searchPopup = renderDOM(searchPopupHtml);

	const searchButton = searchPopup.getElementsByClassName(CSS_CLASSES.UT_SEARCH_BUTTON)[0];
	const closeButton = searchPopup.getElementsByClassName(CSS_CLASSES.UT_SEARCH_CLOSE)[0];
	const inputField = searchPopup.getElementsByClassName(CSS_CLASSES.UT_SEARCH_INPUT)[0];

	searchButton.appendChild(renderDOM(searchIconHtml));
	searchButton.addEventListener('click', e => {
		e.stopPropagation();
		e.preventDefault();
		if (searchCallback) {
			searchCallback(inputField.value);
		}
	});

	closeButton.appendChild(renderDOM(xIconHtml));
	closeButton.addEventListener('click', e => {
		e.stopPropagation();
		e.preventDefault();
		closeSearchForm();
	});

	inputField.addEventListener('keydown', e => {
		if ((e.key === "Enter" || e.key === "Return") && searchCallback) {
			e.preventDefault();
			e.stopPropagation();
			searchCallback(inputField.value);
		}
	});
	inputField.placeholder = placeholder;

	searchPopup.addEventListener('keydown', e => {
		if (e.key === "Escape") {
			e.preventDefault();
			e.stopPropagation();
			closeSearchForm();
		}
	});

	searchPopup.addEventListener('click', e => {
		if (e.target === searchPopup) {
			e.stopPropagation();
			closeSearchForm();
		}
	});

	return searchPopup;
}