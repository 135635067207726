import menuParent from "./menuParent.js";
import {CSS_CLASSES} from "../misc/cssClasses";
import closeMainMenu from "./closeMainMenu.js";
import {closePopup} from "../popupPanel/popupPanel.js";

/**
 * Close the menu items parent menu (either a Hiveburger menu or an Action Item menu popup)
 * @param {HTMLElement} ele - the menuItem from where to begin looking for a parent to close.
 */
export default function closeMenuItemParent(ele) {
	const parent = menuParent(ele);

	// If the element is not inside the body we assume it has already
	// been removed from the dom and there is nothing to do here
	if (parent.className === CSS_CLASSES.HIVEBURGER_MAIN_MENU) {
		closeMainMenu();
	} else if (parent.className === CSS_CLASSES.UT_ACTION_ITEM) {
		closePopup(parent.element);
	} else if (parent.className === 'body') {
		console.error('closeMenuItemParent: Parent not found for this menu item.', ele);
	}
}