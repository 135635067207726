import ENVIRONMENT from "../misc/environment";
import URLS from "../misc/urls";

// when getting a default, make sure to clone objects instead of using directly!
export const globalsDefaults = {
	utahIdURL: URLS.UTAH_ID,
	utahIdURLs: null,
	environment: ENVIRONMENT.PROD,
	utahIdParams: [],
	actionItems: {},
	currentConfig: {},
};

/**
 * Global object shared across the project
 * full deep clone of defaults
 */
export default JSON.parse(JSON.stringify(globalsDefaults));
