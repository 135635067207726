import centerElement from "../misc/centerElement";
import popupPanelHtml from "./popupPanel.html";
import {CSS_CLASSES} from "../misc/cssClasses";
import renderDOM from "../misc/renderDOM";
import removePopups from "./removePopups";

/**
 * Tests if a popup panel is open inside a parent node
 * @param {Node} parent - The parent item that we check to see if it has an open popup panel
 * @returns {boolean}
 */
export function isPopupOpen(parent) {
	let isPopupOpen = false;

	if ((parent !== null) && (parent !== undefined)) {
		let popup = parent.querySelector('.' + CSS_CLASSES.UT_POPUP_PANEL + ':not(.' + CSS_CLASSES.UT_POPUP_PANEL_CLOSE + ')');
		isPopupOpen = ((popup !== null) && (popup !== undefined));
	}
	return isPopupOpen;
}

/**
 * Opens a Popup panel with the content inside
 * @param {Node} parent - The parent item
 * @param {Boolean} posInParent - Position the popup panel inside the parent node
 * @param {Number|String} posTop - Arbitrary top position for the popup
 * @param {Number|String} posLeft - Arbitrary left position for the popup
 * @param {String} headerStyle - CSS class that determines look and feel of the popup. See HEADER_STYLE enum
 * @param {HTMLElement} content - The DOM content to show in the popup
 */
export function openPopup({ parent, posInParent = true, posTop, posLeft, headerStyle, content } = {}) {
	if ((parent !== null) && (parent !== undefined)) {
		//remove other popups
		removePopups();

		//create new popup
		let popup = renderDOM(popupPanelHtml);
		if (headerStyle) {
			popup.classList.add(headerStyle);
		}

		popup.appendChild(content);

		if (posInParent) {
			parent.appendChild(popup);
		} else {
			document.body.appendChild(popup);
		}

		if (posTop && posLeft) {
			popup.style.top = `${posTop}px`;
			popup.style.left = `${posLeft}px`;
		} else {
			centerElement({
				parent: parent,
				ele: popup
			});
		}

		// comment out the following lines to prevent popup from closing
		if (posInParent) {
			parent.addEventListener('mouseleave', e => {
				closePopup(parent);
			});
		} else {
			popup.addEventListener('mouseleave', e => {
				closePopup(parent);
			});
		}
	}
}

/**
 * Close the popup found inside the parent node
 * @param {Node} parent - The parent item that we check to see if it has an open popup panel
 */
export function closePopup(parent) {
	if ((parent !== null) && (parent !== undefined)) {
		let popup = parent.querySelector('.' + CSS_CLASSES.UT_POPUP_PANEL + ':not(.' + CSS_CLASSES.UT_POPUP_PANEL_CLOSE + ')');

		if (popup) {
			popup.classList.add(CSS_CLASSES.UT_POPUP_PANEL_CLOSE);

			let t = setTimeout(() => {
				if (popup.parentNode) {
					popup.parentNode.removeChild(popup);
				}
				clearTimeout(t);
			}, 400);
		}
	}
}

/**
 * Opens and closes a popup panel based on its current state
 * This is the default export so it IS the exported popupPanel() function
 *
 * @param {Node} parent - The parent item
 * @param {Boolean} posInParent - Position the popup panel inside the parent node
 * @param {Number|String} posTop - Arbitrary top position for the popup
 * @param {Number|String} posLeft - Arbitrary left position for the popup
 * @param {String} headerStyle - CSS class that determines look and feel of the popup. See HEADER_STYLE enum
 * @param {HTMLElement} content - The DOM content to show in the popup
 * @param {Boolean} stayOpen - If true then the popupPanel will not toggle to being closed if already open
 */
export function togglePopup ({ parent, posInParent = true, posTop, posLeft, headerStyle, content, stayOpen } = {}) {
	const popupIsOpen = isPopupOpen(parent);
	if (!stayOpen && popupIsOpen) {
		closePopup(parent);
	} else if (!popupIsOpen) {
		openPopup({parent, posInParent, posTop, posLeft, headerStyle, content});
	}
}

export default togglePopup;
