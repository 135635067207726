const HIVEBURGER_MAIN_MENU = 'hiveburger-main-menu';
const UT_MENU_ITEM = 'ut-menu-item';
const UT_ACTION_ITEM = 'ut-action-item';
const UT_MOBILE_ACTION_ITEMS = 'ut-mobile-action-items';
const HIVEBURGER = 'hiveburger';
const UT_POPUP = 'ut-popup';
const UT_SEARCH = 'ut-search-form';
const UT_COLOR = 'ut-color';
const UT_SIZE = 'ut-size';
const UTAH_GOV_HEADER = 'utah-gov-header';
const UT_POPUP_PANEL = 'ut-popup-panel';
const UTAH_GOV_LOGO = 'utah-gov-logo';

/**
 * An enum for CSS classes used in the utah.gov header
 * @readonly
 * @enum {string}
 */
const CSS_CLASSES = {
	UTAH_GOV_HEADER: `${UTAH_GOV_HEADER}`,

	UTAH_GOV_HEADER_LOGO_TITLE_WRAPPER: `${UTAH_GOV_HEADER}__logo-title-wrapper`,
	UTAH_GOV_HEADER_LOGO_TITLE_INNER_WRAPPER: `${UTAH_GOV_HEADER}__logo-title-inner-wrapper`,
	UTAH_GOV_HEADER_TITLE_WRAPPER: `${UTAH_GOV_HEADER}__title-wrapper`,
	UTAH_GOV_HEADER_TITLE: `${UTAH_GOV_HEADER}__title`,
	UTAH_GOV_HEADER_SHORT_TITLE: `${UTAH_GOV_HEADER}__short-title`,
	UTAH_GOV_HEADER_LOGO_WRAPPER: `${UTAH_GOV_HEADER}__logo-wrapper`,
	UTAH_GOV_HEADER_LOGO_AND_TITLE: `${UTAH_GOV_HEADER}__logo-and-title`,

	UTAH_GOV_HEADER_VERT_LINE: `${UTAH_GOV_LOGO}__vert-line`,
	UTAH_GOV_HEADER_STICKY: `${UTAH_GOV_HEADER}--sticky`,
	UTAH_GOV_HEADER_STICKY_PLACEHOLDER: `${UTAH_GOV_HEADER}__placeholder`,

	UT_COLOR_BLUE: `${UT_COLOR}-blue`,
	UT_COLOR_BLUE_DARK: `${UT_COLOR}-blue-dark`,
	UT_COLOR_DARK: `${UT_COLOR}-dark`,
	UT_COLOR_GREEN: `${UT_COLOR}-green`,
	UT_COLOR_GREEN_DARK: `${UT_COLOR}-green-dark`,
	UT_COLOR_RED: `${UT_COLOR}-red`,
	UT_COLOR_RED_DARK: `${UT_COLOR}-red-dark`,
	UT_COLOR_YELLOW: `${UT_COLOR}-yellow`,
	UT_COLOR_YELLOW_DARK: `${UT_COLOR}-yellow-dark`,

	UT_SIZE_SMALL: `${UT_SIZE}-small`,
	UT_SIZE_MEDIUM: `${UT_SIZE}-medium`,
	UT_SIZE_MEDIUM_LARGE: `${UT_SIZE}-medium-large`,
	UT_SIZE_LARGE: `${UT_SIZE}-large`,

	HIVEBURGER: HIVEBURGER,
	HIVEBURGER__OPEN: `${HIVEBURGER}--open`,

	HIVEBURGER_MAIN_MENU: HIVEBURGER_MAIN_MENU,
	HIVEBURGER_MAIN_MENU__CONTAINER: `${HIVEBURGER_MAIN_MENU}__ut-container`,
	HIVEBURGER_MAIN_MENU__UT_LOGO: `${HIVEBURGER_MAIN_MENU}__ut-logo`,
	HIVEBURGER_MAIN_MENU__UT_MENU: `${HIVEBURGER_MAIN_MENU}__ut-menu`,
	HIVEBURGER_MAIN_MENU__CUSTOM_MENU: `${HIVEBURGER_MAIN_MENU}__custom-menu`,
	HIVEBURGER_MAIN_MENU__OPEN: `${HIVEBURGER_MAIN_MENU}--open`,
	SLIDE_IN: 'slide-in',
	SLIDE_OUT: 'slide-out',
	HIVEBURGER_UTAH_MENU_BACKGROUND: 'hive-background',
	HIVEBURGER_MAIN_MENU_STICKY: `${HIVEBURGER_MAIN_MENU}--sticky`,

	UT_MENU_ITEM: UT_MENU_ITEM,
	UT_MENU_ITEM__CLOSED: `${UT_MENU_ITEM}--closed`,
	UT_MENU_ITEM__OPEN: `${UT_MENU_ITEM}--open`,
	UT_MENU_ITEM__SELECTED: `${UT_MENU_ITEM}--selected`,
	UT_MENU_ITEM__CHILDREN: `${UT_MENU_ITEM}__children`,
	UT_MENU_ITEM__ICON: `${UT_MENU_ITEM}__icon`,
	UT_MENU_ITEM__TEXT: `${UT_MENU_ITEM}__text`,
	UT_MENU_ITEM__EXTERNAL_LINK_TEXT: `${UT_MENU_ITEM}__ext`,

	UT_ACTION_ITEMS_WRAPPER: `${UTAH_GOV_HEADER}__action-items-wrapper`,
	UT_ACTION_ITEMS_WRAPPER_VISIBLE: `${UTAH_GOV_HEADER}__action-items-wrapper--visible`,
	UT_ACTION_ITEMS_MOBILE: UT_MOBILE_ACTION_ITEMS,
	UT_ACTION_ITEMS_MOBILE_ICON: `${UT_MOBILE_ACTION_ITEMS}__icon`,

	UT_ACTION_ITEM: UT_ACTION_ITEM,
	UT_ACTION_ITEM__LINK: `${UT_ACTION_ITEM}__link`,
	UT_ACTION_ITEM__ICON: `${UT_ACTION_ITEM}__icon`,
	UT_ACTION_ITEM__TITLE: `${UT_ACTION_ITEM}__title`,
	UT_ACTION_ITEM_SHOWING: `${UT_ACTION_ITEM}--showing`,

	UT_ACTION_ITEM_UTAH_ID: `${UT_ACTION_ITEM}--utah-id`,
	UT_ACTION_ITEM_UTAH_ID_AUTH: `${UT_ACTION_ITEM}--auth`,
	UT_ACTION_ITEM_UTAH_ID_NOAUTH: `${UT_ACTION_ITEM}--noauth`,

	UT_POPUP_BACKDROP: `${UT_POPUP}__backdrop`,
	UT_POPUP_CLOSE: `${UT_POPUP}__backdrop--close`,
	UT_POPUP_WRAPPER: `${UT_POPUP}__wrapper`,

	UT_SEARCH: UT_SEARCH,
	UT_SEARCH_LABEL: `${UT_SEARCH}__label`,
	UT_SEARCH_INPUT: `${UT_SEARCH}__input`,
	UT_SEARCH_BUTTON: `${UT_SEARCH}__button`,
	UT_SEARCH_CLOSE: `${UT_SEARCH}__close`,

	UT_POPUP_PANEL: UT_POPUP_PANEL,
	UT_POPUP_PANEL_CLOSE: `${UT_POPUP_PANEL}--close`,
	UT_POPUP_PANEL_MENU: `${UT_POPUP_PANEL}__menu`,
	UT_POPUP_PANEL_MENU_GRID: `${UT_POPUP_PANEL}__menu--grid`,
	UT_POPUP_PANEL_MENU_CARD: `${UT_POPUP_PANEL}__menu--card`,
	UT_POPUP_PANEL_CUSTOM_CONTENT: `${UT_POPUP_PANEL}__content`,

};
export { CSS_CLASSES };
