import {CSS_CLASSES} from "./cssClasses";

/**
 * An enum for the major style colors for the utah.gov header
 * @readonly
 * @enum {string}
 */
export default {
	BLUE: CSS_CLASSES.UT_COLOR_BLUE,
	BLUE_DARK: CSS_CLASSES.UT_COLOR_BLUE_DARK,
	DARK: CSS_CLASSES.UT_COLOR_DARK,
	DEFAULT: 'default',
	GREEN: CSS_CLASSES.UT_COLOR_GREEN,
	GREEN_DARK: CSS_CLASSES.UT_COLOR_GREEN_DARK,
	RED: CSS_CLASSES.UT_COLOR_RED,
	RED_DARK: CSS_CLASSES.UT_COLOR_RED_DARK,
	YELLOW: CSS_CLASSES.UT_COLOR_YELLOW,
	YELLOW_DARK: CSS_CLASSES.UT_COLOR_YELLOW_DARK,
};