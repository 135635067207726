import utGlobals from "../app/globals";

/**
 * Return the current header's menu configuration.
 * 
 * @see {@link setMainMenu} for an explanation of the object that will be returned.
 */
export default function getMainMenu() {
    return utGlobals.currentMainMenu;
}
