import utGlobals from "../app/globals";
import setConfig from "../app/setConfig";

/**
 * Remove a custom action item from the header.
 * @param {string} actionItemName - Unique name for the ActionItem, as originally provided to addActionItem().
 */
export default function removeActionItem({actionItemName}) {
	delete utGlobals.actionItems[actionItemName];
	setConfig(utGlobals.currentConfig);
}