import {CSS_CLASSES} from "../misc/cssClasses";
import MenuItem from "./MenuItem";
import utGlobals from "../app/globals";
import areAnyChildrenOpen from "./areAnyChildrenOpen.js";

/**
 * Set the menu for the Utah.gov main menu
 *
 * This function should be called after 'ugovHeaderLoaded' event has fired on
 * document or window.
 *
 * If this function is called before the 'ugovHeaderLoaded' has fired, then
 * this function will add the appropriate listener to call itself.
 *
 * @param {Object[]} menu - Array of menu items {children, onClick, icon, iconType, open, openInNewTab, selected, text, url}
 *
 * @param {Object[]} menu.children Array of children menu items
 * @param {function} menu.onClick - Function to call when the user clicks the menu item
 * @param {string|function} menu.icon - String, url, or function representing the icon. See ICON_TYPES enum
 * @param {string} menu.iconType - The type of icon being used. See ICON_TYPES enum
 * @param {boolean} menu.open - Is menu item open
 * @param {boolean} menu.openInNewTab - Open url in a new tab
 * @param {boolean} menu.selected - Is the menu item selected
 * @param {string} menu.text - The text of the menu item
 * @param {string} menu.url - The URL of the menu item
 */
export default function setMainMenu(menu) {
	utGlobals.currentMainMenu = menu;

	const mainMenu = document.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU)[0];
	let customMenu = document.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU__CUSTOM_MENU);

	if (!mainMenu) {
		// The mainmenu DOM element is not present yet.
		// Re-run this function after the ugovHeaderLoaded event has fired.
		window.addEventListener('ugovHeaderLoaded', function() {
			setMainMenu(menu);
		});
		return;
	}

	//remove the custom menu wrapper from DOM
	Array.prototype.forEach.call(customMenu, i => {
		i.parentNode.removeChild(i);
	});

	//add custom menu wrapper, ul, and menu items inside the main menu
	if (menu && menu.length > 0) {
		customMenu = document.createElement('div');
		customMenu.classList.add(CSS_CLASSES.HIVEBURGER_MAIN_MENU__CUSTOM_MENU);
		const customMenuUL = document.createElement('ul');
		customMenuUL.setAttribute('role', 'navigation');
		customMenu.appendChild(customMenuUL);
		mainMenu.appendChild(customMenu);

		menu.forEach(i => {
			areAnyChildrenOpen(i);
			customMenuUL.appendChild(MenuItem(i))
		});
	}
}
