import utGlobals from "./globals";
import setConfig from "./setConfig";

/**
 * Update the current header config with a partial configuration.
 *
 * Values provided in the partial configuration overwrite values in
 * the current configuration. All others existing values remain as-is.
 *
 * @param {*} partialConfig @see {@link setConfig}
 */
export default function updateConfig(partialConfig = {}) {
    const newConfig = Object.assign({}, utGlobals.currentConfig, partialConfig);
    setConfig(newConfig);
}