/**
 * Render a DOM from a string and return just the DOM child node
 * or HTMLCollection if there is more than one Node rendered
 * @param {string} str - HTML string to become rendered DOM
 * @returns {ChildNode|HTMLCollection}
 */
export default function renderDOM(str) {
	let domParser = new DOMParser();
	const rendered = domParser.parseFromString(str, 'text/html');
	if (rendered.body.children.length > 1) {
		return rendered.body.children;
	} else {
		return rendered.body.firstChild;
	}
}