import MenuItem from "../menu/MenuItem";
import renderIcon from "../misc/renderIcon";
import popupPanel from "../popupPanel/popupPanel";
import popupMenuHtml from "../popupPanel/popupMenu.html";
import actionItem from "./ActionItem.html";
import renderDOM from "../misc/renderDOM";
import {CSS_CLASSES} from "../misc/cssClasses";
import preventDefault from "../misc/preventDefault";

/**
 * Renders an Action Item for the action bar area.
 * @param {string|ChildNode} icon - DOM Node for an icon, should be an SVG
 * @param {string} iconType - The type of icon being used. See ICON_TYPES enum
 * @param {string} title - Title of the action item
 * @param {boolean} showTitle – Should the title always be visible?
 * @param {string} className - CSS classes for the action item
 * @param {function} onClick - Callback for the action item click
 * @param {function} onHover - Callback for the action item hover
 * @param {Object[]} menu - An array of objects that describe a popup menu, same format as the main menu
 * @param {string} menuStyle - The style of the popup menu - use POPUP_MENU_STYLE enum
 * @param {ChildNode} popupContent - Arbitrary dom content for a popup panel
 * @param {string} headerStyle - The current style of the header
 * @returns {ChildNode}
 */
export default function ({ icon, iconType, title, showTitle, className, onClick, onHover, menu, menuStyle, popupContent, headerStyle }) {
	const ai = renderDOM(actionItem);

	if (className) {
		ai.classList.add(...className.split(' '));
	}
	if (showTitle) {
		ai.classList.add(CSS_CLASSES.UT_ACTION_ITEM_SHOWING);
	}

	const iconWrapper = ai.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEM__ICON)[0];
	if (icon) {

		renderIcon({
			icon: icon,
			iconType: iconType,
			iconWrapper: iconWrapper,
			cssClasses: ['svg']
		});
	} else {
		iconWrapper.parentNode.removeChild(iconWrapper);
	}

	ai.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEM__TITLE)[0].appendChild(document.createTextNode(showTitle === false ? '' : title));
	ai.setAttribute('aria-label', title);

	const aiLink = ai.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEM__LINK)[0];
	if (onClick) {
		aiLink.addEventListener('click', e => preventDefault(e) || onClick(e));
	} else {
		aiLink.addEventListener('click', preventDefault, false);
	}

	if (onHover) {
		aiLink.addEventListener('click', e => preventDefault(e) || onHover(e));
		aiLink.addEventListener('mouseenter', onHover);
	} else if (menu) {
		let ul = renderDOM(popupMenuHtml);

		if (menuStyle) {
			ul.classList.add(menuStyle);
		}

		menu.forEach(i => {
			ul.appendChild(MenuItem(i));
		});

		const popupConfig = {
			parent: ai,
			content: ul,
			headerStyle: headerStyle,
		};

		aiLink.addEventListener('click', (e) => {
			e.preventDefault();
			popupPanel(popupConfig);
		});
		aiLink.addEventListener('mouseenter', (e) => {
			popupPanel({...popupConfig, stayOpen: true});
		});
	} else if (popupContent) {
		const contentWrapper = document.createElement('div');
		contentWrapper.classList.add(CSS_CLASSES.UT_POPUP_PANEL_CUSTOM_CONTENT);
		contentWrapper.appendChildAll(popupContent);

		const popupConfig = {
			parent: ai,
			content: contentWrapper,
			headerStyle: headerStyle,
		};

		aiLink.addEventListener('click', (e) => {
			e.preventDefault();
			popupPanel(popupConfig);
		});
		aiLink.addEventListener('mouseenter', (e) => {
			popupPanel({...popupConfig, stayOpen: true});
		});
	}

	return ai;
}
