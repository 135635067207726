import MenuItem from "../menu/MenuItem";
import renderDOM from "../misc/renderDOM";
import popupMenuHtml from "../popupPanel/popupMenu.html";
import removePopups from "../popupPanel/removePopups";

export default function ({ profileURL, signOutCallback }) {
	let utahIdMenu =  [];
	if (profileURL) {
		utahIdMenu.push({
			text: 'UtahID Profile',
			url: profileURL,
			openInNewTab: true,
		});
	}
	utahIdMenu.push({
		text: 'Sign Out',
		url: '#',
		onClick: e => {
			e.preventDefault();
			e.stopPropagation();
			if (signOutCallback) {
				signOutCallback();
				removePopups();
			} else {
				console.warn('You must provide a onUserClickedSignOut function!');
			}
		}
	});

	let ul = renderDOM(popupMenuHtml);

	utahIdMenu.forEach(i => {
		ul.appendChild(MenuItem(i));
	});

	return ul;
}