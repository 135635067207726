import {CSS_CLASSES} from "../misc/cssClasses";
import renderHeaderLogo from "../headerLogo/HeaderLogo.js";
import renderTitle from "../headerTitle/HeaderTitle.js";

/**
 * Renders the Logo and Title
 * @param {string} title - The title of the header
 * @param {string} shortTitle - A shorter title to be used on mobile devices
 * @param {*} logo - Function, svg, dom node, image url
 * @param {string} - Alt text for the logo image for accessibility purposes
 * @param {string} logoType - The type of icon (image) using the ICON_TYPES enum
 * @param {string} url - URL for the header title
 * @param {function} onClick - A function that is called when the user clicks the title
 */
export default function ({title, shortTitle, logo, logoAltText, logoType, url, onClick} = {}) {
	const onClickTitleFn = evt => {
		evt.preventDefault();
		evt.stopPropagation();
		onClick(evt);
	};

	const logoTitleWrapper = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_LOGO_TITLE_WRAPPER)[0];
	let logoTitleWrapperInner = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_LOGO_TITLE_INNER_WRAPPER);
	logoTitleWrapperInner = logoTitleWrapperInner ? logoTitleWrapperInner[0] : null;
	if (logoTitleWrapperInner) {
		logoTitleWrapper.removeChild(logoTitleWrapperInner);
	}

	if (title || logo) {
		const newTag = (url || onClick) ? 'a' : 'div';
		logoTitleWrapperInner = document.createElement(newTag);
		logoTitleWrapperInner.classList.add(CSS_CLASSES.UTAH_GOV_HEADER_LOGO_TITLE_INNER_WRAPPER);
		logoTitleWrapper.appendChild(logoTitleWrapperInner);

		if (url || onClick) {
			if (onClick) {
				logoTitleWrapperInner.addEventListener("click", onClickTitleFn);
				logoTitleWrapperInner.setAttribute('href', '#');
			} else if (url) {
				logoTitleWrapperInner.setAttribute('href', url);
			}
		}

		if (logo && title) {
			//there is both a logo and a title
			logoTitleWrapperInner.classList.add(CSS_CLASSES.UTAH_GOV_HEADER_LOGO_AND_TITLE);
		}

		/**
		 * Add header logo
		 */
		renderHeaderLogo({
			headerTitle: title,
			logo,
			logoAltText,
			logoType,
			url,
			onClick,
		});

		/**
		 * Add header title
		 */
		renderTitle({
			title,
			shortTitle,
			headerLogo: logo,
			headerTitleURL: url,
			onClick,
		});
	}
}