import {CSS_CLASSES} from "../misc/cssClasses";

/**
 * Close the search form overlay
 * Overlay is removed from the DOM
 */
export default function closeSearchForm() {
	const searchPopup = document.getElementsByClassName(CSS_CLASSES.UT_POPUP_BACKDROP)[0];
	searchPopup.classList.add(CSS_CLASSES.UT_POPUP_CLOSE);
	let t = setTimeout(() => {
		if (searchPopup.parentNode) {
			searchPopup.parentNode.removeChild(searchPopup);
		}
		clearTimeout(t);
	},500)
}