import globals from "../app/globals";
import utGlobals, {globalsDefaults} from "../app/globals";
import setActionItemTitle from "../headerActionItems/setActionItemTitle";
import {CSS_CLASSES} from "../misc/cssClasses";
import httpRequest from "../misc/httpRequest";
import stringConstants from "../misc/stringConstants";
import URLS, {generateURL} from '../misc/urls';
import preventDefault from "../misc/preventDefault";

let customClickCallback;

function signInCallback(e) {
	e.preventDefault();
	e.stopPropagation();
	if (customClickCallback) {
		customClickCallback();
	}
}

export function removeOnUserClickedSignIn() {
	customClickCallback = null;
}

export function showSignIn (callback) {
	const ai = document.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID)[0];

	if (ai) {
		ai.removeEventListener('click', preventDefault, false);

		if (callback || customClickCallback) {
			if (!customClickCallback) {
				customClickCallback = callback;
			}
			ai.href = '#';
			ai.addEventListener('click', signInCallback);
		} else {
			const commonSettings = {
				env: globals.environment,
				params: [].concat([URLS.UTAH_ID_GOTO_PARAM(window.location.href)], globals.utahIdParams),
			};
			ai.href = (utGlobals.utahIdURLs && utGlobals.utahIdURLs.signin) ?
				generateURL({
					domain: utGlobals.utahIdURLs.signin,
					path: '',
					...commonSettings,
				})
				: generateURL({
					domain: globals.utahIdURL,
					path: URLS.UTAH_ID_LOGIN,
					...commonSettings,
				});
			ai.addEventListener('click', () => window.location = ai.href);
		}
		ai.classList.add(CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID_NOAUTH);
		ai.classList.remove(CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID_AUTH);

		setActionItemTitle({ actionItem: ai, title: stringConstants.SIGN_IN });
	}
}

export function showUserAsSignedIn (data) {
	const ai = document.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID)[0];

	if (ai) {
		ai.href = "#";
		ai.classList.remove(CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID_NOAUTH);
		ai.classList.add(CSS_CLASSES.UT_ACTION_ITEM_UTAH_ID_AUTH);
		ai.removeEventListener('click', signInCallback);
		ai.addEventListener('click', preventDefault, false);

		setActionItemTitle({ actionItem: ai, title: `${stringConstants.HELLO}${data.first}` });
	}
}

/**
 * Check the user information / current authentication status.
 *
 * The 'ugovAuthChanged' event on the document object is triggered when there
 * is a change in the authenticated status. The event object has a 'userInfo'
 * property that can be used to access the updated userInfo value.
 *
 * The optional authCallback callback function is executed with the current
 * userInfo value when there is a change in the authenticated status.
 *
 * If the 'utahId' element is set on the configuration passed to setConfig()
 * then this function will be called automatically when certain events are
 * triggered. The 'utahId.authCallback' element passed into setConfig() will
 * be passed to this function as the 'authCallback' element.
 *
 * @see {@link setConfig} for configuring the 'utahId' and 'utahId.authCallback'
 * elements.
 *
 * @param {function} authCallback - Triggered when authentication status changes
 */
export function checkUserInfo({ authCallback = undefined } = {}) {

	function triggerAuthEvent (authenticated, data) {
		if (utGlobals.auth !== authenticated) {
			utGlobals.auth = authenticated;

			if (authCallback) authCallback(data);

			// Trigger a custom event ('ugovAuthChanged') that developers can listen for
			// in their applications.
			const event = document.createEvent('CustomEvent');
			event.initEvent('ugovAuthChanged', true, true);
			event.userInfo = data;
			document.dispatchEvent(event);
		}
	}

	httpRequest({
		url: generateURL({
			domain: utGlobals.utahIdURL,
			path: URLS.UTAH_ID_USER_INFO,
			env: utGlobals.environment,
			params: utGlobals.utahIdParams
		}),
		method: 'GET',
		timeout: 10000,
		onResolve: request => {
			if (request.status === 200) {
				let data = JSON.parse(request.response);
				data = data.data;

				// Update globals with current userInfo value.
				utGlobals.userInfo = data;

				if (data.urls) {
					utGlobals.utahIdURLs = data.urls;
				} else {
					utGlobals.utahIdURLs = globalsDefaults.utahIdURLs;
				}

				if (data.authenticated) {
					//user is signed in, show their name
					showUserAsSignedIn(data);
					triggerAuthEvent(true, data);
				} else {
					//show sign in
					showSignIn();
					triggerAuthEvent(false, data);
				}
			}
		},
		onReject: error => {
			console.log('Error in xhr request', error);
			// Update globals with current userInfo value.
			utGlobals.userInfo = {authenticated: false};

			showSignIn();
			triggerAuthEvent(false, error);
		}
	});
}

/**
 * Return the userInfo value from the most recent checkUserInfo() call.
 *
 * If the 'utahId' element of the configuration passed to setConfig() is set
 * to a value that does not evaluate to false, then checkUserInfo() will be
 * executed periodically.
 *
 * @see {@link setConfig} for details on configuring the 'utahId' element
 * for event-driven execution of checks for updates to userInfo.
 *
 * @see {@link checkUserInfo} for manually executing a check for updates
 * to userInfo.
 */
export function getUserInfo() {
	return utGlobals.userInfo ? utGlobals.userInfo : {authenticated: false};
}
