import {CSS_CLASSES} from "../misc/cssClasses";
import headerTitleHtml from "./HeaderTitle.html";
import renderDOM from "../misc/renderDOM";

/**
 * Adds a title to the header, the title is configured based on a logo and a url
 * @param {string} title - The title of the header
 * @param {string} shortTitle - A shorter title to be used on mobile devices
 * @param {object|boolean} headerLogo - Indicates the usage of a header logo. Logo is not rendered here.
 * @param {string} headerTitleURL - URL for the header title
 * @param {function} onClick - A function that is called when the user clicks the title
 */
export default function ({title, shortTitle, headerLogo, headerTitleURL, onClick} = {}) {
	const logoTitleWrapper = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_LOGO_TITLE_INNER_WRAPPER)[0];

	if (title) {
		let titleDOM = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_TITLE_WRAPPER)[0];
		if (titleDOM && titleDOM.parentNode) {
			titleDOM.parentNode.removeChild(titleDOM);
		}

		titleDOM = renderDOM(headerTitleHtml);

		const titleDiv = titleDOM.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_TITLE)[0];
		const shortTitleDiv = titleDOM.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER_SHORT_TITLE)[0];
		titleDiv.appendChild(document.createTextNode(title));
		shortTitleDiv.appendChild(document.createTextNode(shortTitle ? shortTitle : title));
		logoTitleWrapper.appendChild(titleDOM);
	}
}
