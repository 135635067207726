import {CSS_CLASSES} from "../misc/cssClasses";

/**
 * Find the parent of the menu or menu item (Hiveburger Menu or Action Item)
 * @param {HTMLElement} ele - The menu item or menu your trying to find the parent of.
 * @returns {{className: null, element: null}}
 */
export default function menuParent(ele) {
	const result = {
		className: null,
		element: null,
	};
	const hiveBurger = ele.closest(`.${CSS_CLASSES.HIVEBURGER_MAIN_MENU}`);
	const actionItem = ele.closest(`.${CSS_CLASSES.UT_ACTION_ITEM}`);
	const bodyItem = ele.closest('body');

	//if the element is not inside the body we assume it has been removed from the dom
	if (hiveBurger) {
		result.className = CSS_CLASSES.HIVEBURGER_MAIN_MENU;
		result.element = hiveBurger;
	} else if (actionItem) {
		result.className = CSS_CLASSES.UT_ACTION_ITEM;
		result.element = actionItem;
	} else if (bodyItem) {
		console.warn('menuParent: No parent found for menu / menu item')
		result.className = 'body';
		result.element = bodyItem;
	}

	return result;
}