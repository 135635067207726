import renderIcon from "../misc/renderIcon";
import menuItemHtml from "./MenuItem.html";
import arrowRightHtml from "../misc/icons/ArrowIcon.html";
import plusIconHtml from "../misc/icons/IconPlus.html";
import renderDOM from "../misc/renderDOM";
import {CSS_CLASSES} from "../misc/cssClasses";
import externalLinkHtml from "../misc/icons/externalLink.html";
import closeMenuItemParent from "./closeMenuItemParent.js";

/**
 * Renders a single menu item.
 * Menu items are rendered in the main menu and in popup panels for action items
 * @param {Object[]} children Array of children menu items
 * @param {function} onClick - Function to call when the user clicks the menu item
 * @param {string|function} icon - String, url, or function representing the icon. See ICON_TYPES enum
 * @param {string} iconType - The type of icon being used. See ICON_TYPES enum
 * @param {boolean} open - Is menu item open
 * @param {boolean} openInNewTab - Open url in a new tab
 * @param {boolean} selected - Is the menu item selected
 * @param {string} text - The text of the menu item
 * @param {string} url - The URL of the menu item
 * @returns {ChildNode}
 */
export default function MenuItem ({children, onClick, icon, iconType, open, openInNewTab, selected, text, url}) {
	const menuItem = renderDOM(menuItemHtml);
	const aTag = menuItem.getElementsByTagName('a')[0];

	function setOpen(menuItem, open) {
		if (open) {
			menuItem.classList.add(CSS_CLASSES.UT_MENU_ITEM__OPEN);
			menuItem.classList.remove(CSS_CLASSES.UT_MENU_ITEM__CLOSED);
		} else {
			menuItem.classList.remove(CSS_CLASSES.UT_MENU_ITEM__OPEN);
			menuItem.classList.add(CSS_CLASSES.UT_MENU_ITEM__CLOSED);
		}
	}

	//generate icon
	//default to string representing a DOM element
	if (icon) {
		const iconWrapper = document.createElement("div");
		iconWrapper.classList.add(CSS_CLASSES.UT_MENU_ITEM__ICON);

		renderIcon({
			icon: icon,
			iconType: iconType,
			iconWrapper: iconWrapper,
			cssClasses: ['svg']
		});

		aTag.appendChild(iconWrapper);
	}

	//text and URL
	const textWrapper = document.createElement('span');
	textWrapper.classList.add(CSS_CLASSES.UT_MENU_ITEM__TEXT);
	textWrapper.appendChild(document.createTextNode(text));
	aTag.appendChild(textWrapper);
	aTag.setAttribute('href', url || '#');
	if (openInNewTab) {
		aTag.setAttribute('target', '_blank');

		const externalLink = renderDOM(externalLinkHtml);
		textWrapper.appendChild(externalLink);
	}

	//click callback
	if (onClick) {
		aTag.addEventListener('click', e => {
			e.preventDefault();
			e.stopPropagation();
			closeMenuItemParent(aTag);
			onClick(e);
		});
	} else {
		aTag.addEventListener('click', e => {
			e.stopPropagation();
			if (!children || children.length <= 0) {
				closeMenuItemParent(aTag);
			}
		});
	}

	//children
	if (children && children.length > 0) {
		menuItem.classList.add(CSS_CLASSES.UT_MENU_ITEM__CHILDREN);
		const childUL = document.createElement('ul');
		children.forEach(i => {
			childUL.appendChild(MenuItem(i));
		});
		menuItem.appendChild(childUL);

		aTag.appendChild(renderDOM(plusIconHtml));

		aTag.addEventListener('click', e => {
			e.preventDefault();
			e.stopPropagation();
			setOpen(menuItem, !menuItem.classList.contains(CSS_CLASSES.UT_MENU_ITEM__OPEN));
		});

		//open
		setOpen(menuItem, open);

	} else {
		//selected icon
		if (selected) {
			aTag.appendChild(renderDOM(arrowRightHtml));
		}
	}

	//selected class
	if (selected) {
		menuItem.classList.add(CSS_CLASSES.UT_MENU_ITEM__SELECTED);
	} else {
		menuItem.classList.remove(CSS_CLASSES.UT_MENU_ITEM__SELECTED);
	}

	return menuItem;
};
