import {CSS_CLASSES} from "../misc/cssClasses.js";
import renderDOM from "../misc/renderDOM.js";
import kebabHtml from "./Kebab.html";
import kebabIcon from "../misc/icons/KebabIcon.html";
import closeMainMenu from "../menu/closeMainMenu.js";

const setupKebab = function (renderedActionItems) {
	let kebabWrapper = document.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEMS_MOBILE)[0];
	if (renderedActionItems && !kebabWrapper) {
		kebabWrapper = renderDOM(kebabHtml);
		const kebabDiv = kebabWrapper.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEMS_MOBILE_ICON)[0];
		const actionItemsWrapper = document.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEMS_WRAPPER)[0];
		const header = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER)[0];
		kebabDiv.appendChildAll(renderDOM(kebabIcon));

		kebabWrapper.addEventListener('click', e => {
			e.preventDefault();
			e.stopPropagation();
			const actionItemsWrapper = document.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEMS_WRAPPER)[0];
			if (actionItemsWrapper.classList.contains(CSS_CLASSES.UT_ACTION_ITEMS_WRAPPER_VISIBLE)) {
				closeKebab();
			} else {
				openKebab();
				closeMainMenu();
			}
		});
		header.insertBefore(kebabWrapper, actionItemsWrapper);

		document.addEventListener('click', function (e) {
			const inWrapper = !!e.target.closest('.' + CSS_CLASSES.UT_ACTION_ITEMS_WRAPPER);
			if (!inWrapper && kebabDiv.isVisible()) {
				closeKebab();
			}
		})
	}
};

const openKebab = function () {
	const actionItemsWrapper = document.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEMS_WRAPPER)[0];
	actionItemsWrapper.classList.add(CSS_CLASSES.UT_ACTION_ITEMS_WRAPPER_VISIBLE);
};

const closeKebab = function () {
	const actionItemsWrapper = document.getElementsByClassName(CSS_CLASSES.UT_ACTION_ITEMS_WRAPPER)[0];
	actionItemsWrapper.classList.remove(CSS_CLASSES.UT_ACTION_ITEMS_WRAPPER_VISIBLE);
};

export {setupKebab, openKebab, closeKebab};