import {CSS_CLASSES} from "../misc/cssClasses";

let headerResizeDebounceId = null;
let headerResizeFunc = null;
let lastKnownHeaderHeight = null;
const HEADER_RESIZE_DEBOUNCE_RATE = 250;

const getHeaderHeight = () => {
	const header = document.getElementsByClassName(CSS_CLASSES.UTAH_GOV_HEADER)[0];
	return header && header.offsetHeight;
};

const createHeaderResizeFunc = onHeaderResize => () => {
	if (headerResizeDebounceId) {
		clearTimeout(headerResizeDebounceId);
		headerResizeDebounceId = null;
	}

	headerResizeDebounceId = setTimeout(() => {
		const headerHeight = getHeaderHeight();
		if (headerHeight && lastKnownHeaderHeight && headerHeight !== lastKnownHeaderHeight) {
			onHeaderResize(headerHeight);
		}
		lastKnownHeaderHeight = headerHeight;
	}, HEADER_RESIZE_DEBOUNCE_RATE);
};

export default ({onHeaderResize}, {onHeaderResize: previousOnHeaderResize}) => {
	if (headerResizeFunc && (!onHeaderResize || (previousOnHeaderResize !== onHeaderResize))) {
		window.removeEventListener('resize', headerResizeFunc);
		headerResizeFunc = null;
	}

	if (!headerResizeFunc && onHeaderResize) {
		window.addEventListener('resize', headerResizeFunc = createHeaderResizeFunc(onHeaderResize));
	}
	lastKnownHeaderHeight = getHeaderHeight();
}
