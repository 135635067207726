/**
 * This file should be used to setup Prototype functions,
 * and should be called once at App start to make the function available.
 */

/**
 * Append an HTMLCollection or Node to another Node
 * @param {HTMLCollection|Node} child - HTMLCollection or Node to append to another Node
 */
Node.prototype.appendChildAll = function (child) {
	if (child instanceof HTMLCollection) {
		while (child.length > 0) {
			this.appendChild(child[0]);
		}
	} else if (child instanceof Node) {
		this.appendChild(child);
	} else {
		console.error('Node.appendChildAll: Unknown child element!');
	}
};

/**
 * Fallback for HTMLElement.closest() for IE
 */
if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;

		do {
			if (Element.prototype.matches.call(el, s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

if (!Element.prototype.isVisible) {
	Element.prototype.isVisible = function () {
		return !!( this.offsetWidth || this.offsetHeight || this.getClientRects().length );
	}
}