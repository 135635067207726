import actionItemsWrapper from '../headerActionItems/ActionItemsWrapper.html';
import logoTitleWrapper from './LogoTitleWrapper.html';
import HiveBurger from "../hiveburger/HiveBurger";
import renderDOM from "../misc/renderDOM";
import UtahGovLogo from "../utahGovLogo/UtahGovLogo";
import headerWrapper from "./HeaderWrapper.html";

/**
 * Creates the header wrapper DOM and appends the
 * HiveBurger, Utah.gov logo, flexible space, and action items wrapper to it.
 * @returns {ChildNode}
 */
export default function () {
	const header = renderDOM(headerWrapper);

	header.appendChildAll(HiveBurger());
	header.appendChildAll(UtahGovLogo());
	header.appendChildAll(renderDOM(logoTitleWrapper));
	header.appendChildAll(renderDOM(actionItemsWrapper));

	return header;
}
