import { CSS_CLASSES } from "../misc/cssClasses";

/**
 * Removes all popup panels from the DOM
 */
export default function () {
	//remove other popups
	const popups = document.getElementsByClassName(CSS_CLASSES.UT_POPUP_PANEL);
	Array.prototype.forEach.call(popups, i => {
		if (i.parentNode) {
			i.parentNode.removeChild(i);
		}
	});
}