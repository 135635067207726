/**
 * Recurse through the menu to open all parents of open or selected menu items
 *
 * @param {Object[]} menu - Array of menu items {children, onClick, icon, iconType, open, openInNewTab, selected, text, url}
 * @returns {boolean}
 */
export default function areAnyChildrenOpen(menu) {
	let menuIsOpen = menu && (menu.open || menu.selected);
	if (menu && menu.children) {
		menu.children.forEach(menuItem => (
			menuIsOpen = areAnyChildrenOpen(menuItem) ||
				menuItem.open ||
				menuItem.selected ||
				menuIsOpen
		));
	}
	menu.open = menuIsOpen;
	return menuIsOpen;
}