import utGlobals from "../app/globals";
import setConfig from "../app/setConfig";

/**
 * Add a custom action item to the header.
 * @param {string} actionItemName - Unique name for the ActionItem; can be used by removeActionItem() later.
 * @param {Object} config - ActionItem configuration object.
 * 		@param {string|ChildNode} config.icon - DOM Node for an icon, should be an SVG
 * 		@param {string} config.iconType - The type of icon being used. See ICON_TYPES enum
 * 		@param {string} config.title - Title of the action item
 * 		@param {boolean} config.showTitle – Should the title always be visible?
 * 		@param {string} config.className - CSS classes for the action item
 * 		@param {function} config.onClick - Callback for the action item click
 * 		@param {function} config.onHover - Callback for the action item hover
 * 		@param {Object[]} config.menu - An array of objects that describe a popup menu, same format as the main menu
 * 		@param {string} config.menuStyle - The style of the popup menu - use POPUP_MENU_STYLE enum
 * 		@param {ChildNode} config.popupContent - Arbitrary dom content for a popup panel
 */
export default function addActionItem({actionItemName, config}) {
	utGlobals.actionItems[actionItemName] = config;
	setConfig(utGlobals.currentConfig);
}