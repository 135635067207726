import hiveBurger from "./HiveBurger.html";
import renderDOM from "../misc/renderDOM";
import { CSS_CLASSES } from "../misc/cssClasses";
import { openMainMenu, closeMainMenu } from "../app/Public";

export default function () {
	const hb = renderDOM(hiveBurger);

	hb.addEventListener('click', e => {
		const mainMenu = document.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU)[0];
		e.preventDefault();
		e.stopPropagation();
		if (mainMenu.classList.contains(CSS_CLASSES.HIVEBURGER_MAIN_MENU__OPEN)) {
			closeMainMenu();
		} else {
			openMainMenu();
		}
	});

	document.addEventListener('click', e => {
		const mainMenu = document.getElementsByClassName(CSS_CLASSES.HIVEBURGER_MAIN_MENU)[0];
		if (mainMenu.classList.contains(CSS_CLASSES.HIVEBURGER_MAIN_MENU__OPEN)) {
			closeMainMenu();
		}
	});

	return hb;
}